import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { isListingFavorite } from '../../util/data';
import { useUpdateProfileMutation } from '../../ducks/UserApi';

import css from './FavoriteListing.module.css';

const FavoriteListingComponent = props => {
  const [
    updateProfile,
    { isLoading: updateInProgress, error: updateError },
  ] = useUpdateProfileMutation();
  const { className, rootClassName, listing, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);
  const isFavorite = isListingFavorite({ currentUser, listing });
  const history = useHistory();

  const update = e => {
    e.stopPropagation();
    e.preventDefault();

    if (!currentUser?.id?.uuid) {
      history.push("/signup");
      return;
    }

    const favoriteListings = currentUser?.attributes?.profile?.publicData?.favoriteListings || [];
    const listingId = listing?.id?.uuid;
    let favorites;

    if (updateInProgress) {
      return;
    }

    if (isFavorite) {
      favorites = favoriteListings.filter(v => v !== listingId);
    } else {
      favorites = [...favoriteListings, listingId];
    }

    const updateValues = {
      publicData: {
        favoriteListings: favorites,
      },
    };

    return updateProfile(updateValues);
  };

  return (
    <div className={classes} onClick={e => update(e)}>
      {updateError && (
        <p className={css.error}>
          <FormattedMessage id="FavoriteListing.updateFailed" />
        </p>
      )}
      <FontAwesomeIcon icon={isFavorite ? faHeartSolid : faHeart} spin={updateInProgress} />
    </div>
  );
};

FavoriteListingComponent.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

FavoriteListingComponent.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({});

const FavoriteListing = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(FavoriteListingComponent);

export default FavoriteListing;
